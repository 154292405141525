// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-krakow-sky-run-js": () => import("./../../../src/pages/krakow-sky-run.js" /* webpackChunkName: "component---src-pages-krakow-sky-run-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-offices-js": () => import("./../../../src/pages/offices.js" /* webpackChunkName: "component---src-pages-offices-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-biura-js": () => import("./../../../src/pages/pl/biura.js" /* webpackChunkName: "component---src-pages-pl-biura-js" */),
  "component---src-pages-pl-blog-js": () => import("./../../../src/pages/pl/blog.js" /* webpackChunkName: "component---src-pages-pl-blog-js" */),
  "component---src-pages-pl-covid-19-js": () => import("./../../../src/pages/pl/covid-19.js" /* webpackChunkName: "component---src-pages-pl-covid-19-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-kontakt-potwierdzenie-js": () => import("./../../../src/pages/pl/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-pl-kontakt-potwierdzenie-js" */),
  "component---src-pages-pl-krakow-sky-run-js": () => import("./../../../src/pages/pl/krakow-sky-run.js" /* webpackChunkName: "component---src-pages-pl-krakow-sky-run-js" */),
  "component---src-pages-pl-lokale-js": () => import("./../../../src/pages/pl/lokale.js" /* webpackChunkName: "component---src-pages-pl-lokale-js" */),
  "component---src-pages-pl-lokalizacja-js": () => import("./../../../src/pages/pl/lokalizacja.js" /* webpackChunkName: "component---src-pages-pl-lokalizacja-js" */),
  "component---src-pages-pl-o-nas-js": () => import("./../../../src/pages/pl/o-nas.js" /* webpackChunkName: "component---src-pages-pl-o-nas-js" */),
  "component---src-pages-pl-polityka-cookies-js": () => import("./../../../src/pages/pl/polityka-cookies.js" /* webpackChunkName: "component---src-pages-pl-polityka-cookies-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-pl-unity-eye-js": () => import("./../../../src/pages/pl/unity-eye.js" /* webpackChunkName: "component---src-pages-pl-unity-eye-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-unity-eye-js": () => import("./../../../src/pages/unity-eye.js" /* webpackChunkName: "component---src-pages-unity-eye-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

